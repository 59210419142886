import React, { useEffect } from "react"
import {every, isEmpty, map, some} from "lodash"
import _ from "lodash"
import { WithChampionshipBet, useRound1, useRound2, useRound3, useOverview, useChampionshipBet } from "./context"
import {ChampionshipBetPick, ChampionshipBetTeam, PickValue, TChampionshipBet, TeamId} from "./types"
import usePicker, { WithPicker } from "./usePicker"
import { ChangeTeam, PairingRow, PickedProgress, RankingRow, SectionHeader, SelectTeam, SelectWinner , SelectionRow } from "./ui"
import I18n from "i18n-js"
import {buildPicks, pickIsSet} from "./helper"
import useTutorialPopup, { WithTutorialPopup } from "./useTutorialPopup"

const Overview = () => {

  const { pickedPercentRound1, pickedPercentRound2, pickedPercentRound3 } = useOverview()

  return <div className="championshipBet__overview">
    <a href="#round1" className="championshipBet__overview__rowLabel">
      {I18n.t("championshipbet.round1.section_header.title") + " ↴"}
    </a>
    <PickedProgress pickedPercent={pickedPercentRound1} />
    <a href="#round2" className="championshipBet__overview__rowLabel">
      {I18n.t("championshipbet.round2.section_header.title") + " ↴"}
    </a>
    <PickedProgress pickedPercent={pickedPercentRound2} />
    <a href="#round3" className="championshipBet__overview__rowLabel">
      {I18n.t("championshipbet.round3.section_header.title") + " ↴"}
    </a>
    <PickedProgress pickedPercent={pickedPercentRound3} />
  </div>
}

const isWinner = (points: PickValue, team: ChampionshipBetTeam, pickId: ChampionshipBetPick) => {
  return (!!points && points !== 0)
    ? team.id === pickId
    : pickIsSet(pickId) && team.id !== pickId
}

const EmptyTeam = () => {
  return <div className="championshipBet__pairingRow__teamBox championshipBet__pairingRow__teamBox--empty">
    ?
  </div>
}

const Round1 = () => {

  const { pairings, pickedPercent, isOpen, getPick, resetIndices } = useRound1()

  return <div className="championshipBet__round1">
    <SectionHeader
      linkTarget="round1"
      title={I18n.t("championshipbet.round1.section_header.title")}
      infoText={I18n.t("championshipbet.round1.section_header.info_text")}
      pickedPercent={pickedPercent}
    />
    {isEmpty(pairings) && <div className="championshipBet__pairingRow">
      <div className="championshipBet__pairingRow__emptyMessage">{I18n.t("championshipbet.round1.empty")}</div>
    </div>}
    {map(pairings, (pairing, index) => {

      const { pickId, points } = getPick(index)

      return (
        <PairingRow
          key={`pairing_row_r1_${index}`}
          index={index}
          points={points}
          matchText={
            (pickIsSet(pickId))
              ? I18n.t("championshipbet.round1.pairing_row.your_pick")
              : (isOpen
                  ? I18n.t("championshipbet.round1.pairing_row.pick_winner")
                  : I18n.t("championshipbet.round1.pairing_row.no_pick")
                )
            }
        >
          {pairing[0]
            ? <SelectWinner
                index={index}
                picked={pickId === pairing[0].id}
                resetIndices={resetIndices}
                className={[
                  "championshipBet__pairingRow__teamBox",
                  `livTeam__teamBoxCol--${pairing[0].team_key}`,
                ].join(" ")}
                team={pairing[0]}
                parentClass="championshipBet__pairingRow__teamBox"
                isOpen={isOpen}
                isWinner={isWinner(points, pairing[0], pickId)}
                isScored={!!points || points === 0}
                left
              />
            : <EmptyTeam />
          }
          <div className="championshipBet__pairingRow__divider">
            <div>VS</div>
          </div>
          {pairing[1]
            ? <SelectWinner
                index={index}
                picked={pickId === pairing[1].id}
                resetIndices={resetIndices}
                className={[
                  "championshipBet__pairingRow__teamBox",
                  `livTeam__teamBoxCol--${pairing[1].team_key}`,
                ].join(" ")}
                team={pairing[1]}
                parentClass="championshipBet__pairingRow__teamBox"
                isOpen={isOpen}
                isWinner={isWinner(points, pairing[1], pickId)}
                isScored={!!points || points === 0}
                right
              />
            : <EmptyTeam />
          }
        </PairingRow>
      )}
    )}
  </div>
}


const Round2 = () => {
  const {
    options,
    pickedPercent,
    isOpen,
    getPick,
    isPicked,
    pickIndices,
    resetIndices,
    currentIndex
  } = useRound2()
  return <div className="championshipBet__round2">
    <SectionHeader
      linkTarget="round2"
      title={I18n.t("championshipbet.round2.section_header.title")}
      infoText={I18n.t("championshipbet.round2.section_header.info_text")}
      pickedPercent={pickedPercent}
    />
    {isEmpty(options) && <div className="championshipBet__pairingRow__teamBox">
      <div className="championshipBet__pairingRow__emptyMessage">{I18n.t("championshipbet.round2.empty")}</div>
    </div>}
    {map(pickIndices, (pickIndex, index) => {
      const { pickId, points } = getPick(pickIndex)
            const isScored = !!points || points === 0
            const pickedOption = _.find(options, o => o.id === pickId)
            const isCorrect = !isOpen && isScored && pickedOption && isWinner(points, pickedOption, pickId)
            return <div key={pickIndex} className="championshipBet__rankingRow">
              <SelectTeam
                index={pickIndices[index]}
                choices={options}
                pickIndices={pickIndices}
                parentClass={"championshipBet__rankingRow__teamBox"}
                team={pickedOption}
                emptyMessage={I18n.t("championshipbet.ui.ranking_row.empty")}
                isCorrect={isCorrect}
                isOpen={isOpen}
              />
            </div>
//                <SelectionRow
//                    picked={isPicked(candidate.id)}
//                    team={candidate}
//                    isOpen={isOpen}
//                    isWinner={isWinner(points, candidate, pickId)}
//                    pickIndices={pickIndices}
//                    resetIndices={resetIndices}
//                    isScored={!!points || points === 0}
//                    currentIndex={currentIndex}
//                />
      }
    )}
  </div>
}

const Round3 = () => {

  const {
    ranks,
    pickIndices,
    getOptions,
    pickedPercent,
    findTeam,
    currentPick,
    isCorrect,
    points,
    isOpen,
  } = useRound3()

  return <div className="championshipBet__round3">
    <SectionHeader
      linkTarget="round3"
      title={I18n.t("championshipbet.round3.section_header.title")}
      infoText={I18n.t("championshipbet.round3.section_header.info_text")}
      pickedPercent={pickedPercent}
      points={points}
    />
    {map(ranks, (index) => {
      return (
        <RankingRow
          key={`rankingRow_${index}`}
          index={index}
          team={findTeam(index)}
          currentPick={currentPick(index)}
          pickIndices={pickIndices}
          options={getOptions(index)}
          isCorrect={isCorrect(index)}
          isOpen={isOpen}
        />
      )
    })}
  </div>
}

const Teaser = () => {
  return (
    <div className="championshipBet__teaser">
      {I18n.t("championshipbet.teaser.text")}
    </div>
  )
}

const _ChampionshipBet = () => {
  const { bet: { shadow, event_state, picks } } = useChampionshipBet()
  const { openTutorial } = useTutorialPopup()

  useEffect(() => {
    if (!shadow && event_state === "open" && every(picks, (pick) => !pickIsSet(pick))) {
      openTutorial()
    }
  }, [])

  return <WithPicker>
    <div className={"championshipBet" + (shadow ? " championshipBet--shadow" : "")}>
      {shadow && <Teaser />}
      <Overview />
      <Round1 />
      <Round2 />
      <Round3 />
    </div>
  </WithPicker>
}

const ChampionshipBet: React.FC<{authToken: string, bet: TChampionshipBet}> = ({ authToken, bet }) => {
  return <WithChampionshipBet authToken={authToken} initialBet={bet}>
    <WithTutorialPopup>
      <_ChampionshipBet />
    </WithTutorialPopup>
  </WithChampionshipBet>
}

export default ChampionshipBet
